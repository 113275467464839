import React from 'react';

import NewsArticle from '../../../components/news-article/news-article.component'

const Article = () => (
  <NewsArticle>
    <h1 className='page-title'>HSBC UK updates mortgage rates and bonus payments</h1>
    <p>HSBC UK has made changes to its mortgage rates, making one dozen rates cheaper, the bank announced today. The bank has also confirmed that it has updated its lending policy on variable pay.</p>
    <p>Mortgage rate cuts include:</p>
    <ul>
      <li>90% LTV 2 years fixed rate (£999 fee) cut by 0.15% to 3.24%, with the no-fee equivalent cut to 3.44%.</li>
      <li>90% LTV 5 year fixed rate (£999 fee) down by 0.10% to 3.44%, with the no-fee equivalent down by 0.10% to 3.64%</li>
      <li>85% LTV 2 year fixed rate (£999 fee) reduced by 0.10% to 2.54%, (no fee down to 2.84%)</li>
      <li>85% LTV 5 year fixed rate (£999 fee) cut by 0.10% to 2.84% with the no-fee equivalent reduced to 3.14%</li>
    </ul>
    <p>HSBC UK has also made changes to its variable pay policy, enabling income from commission and overtime, in addition to quarterly, half-yearly or annual bonus payments to be used to support mortgage affordability. The most recent payment must have been received in 2021.</p>
    <p>Michelle Andrews, HSBC UK’s Head of Buying a Home, said: “We are all looking forward to normality returning, and the inclusion of overtime, commission and bonuses to support a mortgage application is one bit of normality that will be welcomed by many looking to move onto or up the property ladder.”</p>
  </NewsArticle>
)

export default Article
